<template>
  <div class="rounded-4xl bg-grey flex w-min items-center justify-center px-1">
    <button
      type="button"
      class="pr-2 text-white"
      :class="size === 'small' ? 'pl-1' : 'pl-2'"
      @click="navigateMonth(-1)"
    >
      <UiIcon
        size="medium"
        name="ic:baseline-chevron-left"
      />
    </button>
    <div
      class="flex h-16"
      :class="size === 'small' ? 'text-lg gap-2 px-1' : 'text-xl gap-4 px-2'"
    >
      <button
        type="button"
        class="text-grey-600 w-24 text-center font-bold capitalize max-sm:hidden"
        @click="navigateMonth(-1)"
      >
        {{ prevMonth }}
      </button>
      <span
        class="flex w-24 items-center justify-center font-bold capitalize text-white"
      >
        {{ value.toLocaleString({month: 'long'}) }}
      </span>
      <button
        type="button"
        class="text-grey-600 w-24 text-center font-bold capitalize max-sm:hidden"
        @click="navigateMonth(1)"
      >
        {{ nextMonth }}
      </button>
    </div>
    <button
      type="button"
      class="pl-2 text-white"
      :class="size === 'small' ? 'pr-1' : 'pr-2'"
      @click="navigateMonth(1)"
    >
      <UiIcon
        size="medium"
        name="ic:baseline-chevron-right"
      />
    </button>
  </div>
</template>

<script lang="ts" setup>
import {DateTime} from 'luxon';

defineProps<{
  size?: 'small'
}>();

const value = defineModel<DateTime>({
  default: DateTime.now(),
  required: true,
});

const prevMonth = computed(() => value.value.minus({month: 1}).toLocaleString({month: 'long'}));
const nextMonth = computed(() => value.value.plus({month: 1}).toLocaleString({month: 'long'}));

function navigateMonth(months: number) {
  const year = value.value.year;

  value.value = value.value
    .plus({month: months})
    .set({year});
}
</script>
